import IconPork404 from '@/components/icons/IconPork404';
import BoxFull from '@/components/box/BoxFull';
import { useRouter } from 'next/router';
import ROUTES from '@/lib/routes';

export default function Custom404() {
  const router = useRouter();
  return (
    <BoxFull
      onClose={() => {
        router.replace(ROUTES.home);
      }}
      type={'close'}
      title={'404 PAGE NOT FOUND'}
      mainTitle={''}>
      <div className="flex justify-center items-center">
        <div className="inline-flex w-20">
          <IconPork404 />
        </div>
        <h1 className="text-5xl ml-2">404</h1>
      </div>
      <div className="flex justify-center my-10 items-center">
        <h1 className="text-5xl">Page Not Found</h1>
      </div>
    </BoxFull>
  );
}
